<template>
    <div class="main">
        <van-cell-group>
            <van-cell center is-link to="/goodsAdd">
                <template #title>
                    <van-field  readonly required v-model="spxx.spmc" placeholder="点击选择商品名称" label="商品名称"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field required v-model="spxx.kpje" type="number" @blur="suan" placeholder="点击填写开票金额" label="开票金额"/>
                </template>
            </van-cell>
            <van-cell center is-link @click="spxx.slshow=true">
                <template #title>
                    <van-field readonly required v-model="spxx.rate" placeholder="点击选组税率" label="税率"/>
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group title=" ">
            <van-cell center>
                <template #title>
                    <van-field v-model="spxx.gg" placeholder="点击填写规格型号" label="规格型号"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="spxx.sl" @blur="suan" type="digit" placeholder="点击填写数量" label="数量"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="spxx.dw" placeholder="点击填写单位" label="单位"/>
                </template>
            </van-cell>
            <van-cell center>
                <template #title>
                    <van-field v-model="spxx.hsdj" readonly placeholder="点击填写含税单价" label="含税单价"/>
                </template>
            </van-cell>
        </van-cell-group>

        <div  style="margin:40px 16px;">
            <van-button type="primary" size="large" @click="saveClick">保 存</van-button>
        </div>

        <van-popup v-model:show="spxx.slshow" round position="bottom">
            <van-picker
                    show-toolbar
                    :columns="spxx.columns"
                    @cancel="spxx.slshow = false"
                    @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
    import { ref,reactive,createApp,getCurrentInstance,onMounted } from 'vue';
    import { Cell, CellGroup ,Dialog, Notify} from 'vant';
    export default {
        name: "invoiceAdd",
        setup(){
            const { proxy } = getCurrentInstance();
            const spxx = reactive({
                slshow:false,
                columns:["0%", "1%", "3%", "4%", "5%", "6%", "9%", "10%", "11%", "13%", "16%", "17%"],
                spmc:"",
                rate:"0%",
                kpje:"",
                gg:"",
                sl:"1",
                dw:"",
                hsdj:"",
                mynub:0,
                se1:0,
                je1:0,
                bhsdj:0,
            });
            const loadshuju= proxy.$root.$route.params; //前一页传入的数据
            console.log(loadshuju);
            if(JSON.stringify(loadshuju)!='{}'){
                spxx.spmc = loadshuju.spmc;
                spxx.rate = loadshuju.rate;
                spxx.gg = loadshuju.gg;
                spxx.dw = loadshuju.dw;
                spxx.hsdj = loadshuju.hsdj;
                if(loadshuju.sl=="" || loadshuju.sl<0){
                    spxx.sl='1';
                }else{
                spxx.sl = loadshuju.sl;
                }
            }
            if(sessionStorage.getItem("fpkj-sdkp-mynub")!=null){
                spxx.mynub = JSON.parse(sessionStorage.getItem("fpkj-sdkp-mynub")) 
            }
            
            const suan = ()=>{
                var aa=spxx.rate.split("%")[0]/100; //税率%转小数
                console.log(aa);
                spxx.hsdj = spxx.kpje / spxx.sl;
                spxx.je1 = spxx.kpje / (1+aa);
                spxx.se1 = spxx.je1 * aa;
                spxx.bhsdj = spxx.je1 / spxx.sl;
                spxx.hsdj = spxx.hsdj.toFixed(2);
                spxx.je1 = spxx.je1.toFixed(2);
                spxx.se1 = spxx.se1.toFixed(2);
                spxx.bhsdj = spxx.bhsdj.toFixed(2);
                console.log(spxx.se1);
                console.log(spxx.je1);
                console.log(spxx.bhsdj);

            };
            const saveClick = ()=>{
                spxx.mynub++;
                var arr=[]
                if(spxx.spmc==""){
                    Notify({ type: 'warning', message: '请选择商品名称!' });
                    return
                }
                if(spxx.kpje==""){
                    Notify({ type: 'warning', message: '请输入开票金额!' });
                    return
                }
                if(spxx.rate==""){
                    Notify({ type: 'warning', message: '请选择税率!' });
                    return
                }
                if(spxx.sl==""){
                    Notify({ type: 'warning', message: '请输入数量!' });
                    return
                }
                if(sessionStorage.getItem("fpkj-sdkp-addmx")!=null){
                    arr =JSON.parse(sessionStorage.getItem("fpkj-sdkp-addmx")) 
                }
                var obt={
                    addata:spxx,
                    backdata:loadshuju
                }
                arr.push(obt);
                sessionStorage.setItem("fpkj-sdkp-addmx",JSON.stringify(arr));
                sessionStorage.setItem("fpkj-sdkp-mynub",spxx.mynub);
                proxy.$root.$router.go(-1);
                // proxy.$root.$router.push({
                //     name: "invoicing",
                //     query:{addata:JSON.stringify(spxx),backdata:JSON.stringify(loadshuju)},
                //     meta: {keepAlive: true }// 需要被缓存
                // });
            };
            const onConfirm = (value, index)=> {
                console.log(value);
                spxx.rate = value;
                spxx.slshow = false;
                suan();
            };
            return{
                saveClick,
                onConfirm,
                spxx,
                suan,
            };
        },
        
    }
</script>

<style scoped>
    .main {
        background: #eee;
        min-height: 100vh;
    }
</style>